import { useCallback, useEffect, useRef } from "react";
import type { NextPage } from "next";
import { useRouter } from "next/router";

import { motion, useMotionValue, useTransform, Variants } from "framer-motion";

import { Navbar } from "@components/Navbar";
import Footer from "@components/Footer/Footer";

import { NextSeo } from "next-seo";
import { useUser } from "../utils/AuthContext";
import PricingTable from "@components/Pricing/PricingTable";
import ContactForm from "@components/ContactForm/ContactForm";
import Button from "@components/Utils/Button";

const sectionVariantProps = {
  initial: "hidden",
  whileHover: "hover",
  whileInView: "visible",
  viewport: { once: true },
  transition: { duration: 0.3 },
  variants: {
    visible: {
      opacity: 1,
      backgroundImage:
        "linear-gradient(to right bottom, #f3f3f6, #f6f6f8, #f9f9fa, #fcfcfd, #ffffff)",
    },
    hidden: { opacity: 0 },
    hover: {
      scale: 1.01,
      backgroundImage:
        "linear-gradient(to right bottom, #bbf7d0, #c9f9d9, #d6fae2, #e3fceb, #f0fdf4)",
    },
  } as Variants,
};

const pricingSectionProps = {
  initial: "hidden",
  whileInView: "visible",
  viewport: { once: true },
  transition: { duration: 0.3 },
  variants: {
    visible: {
      opacity: 1,
    },
    hidden: { opacity: 0 },
  } as Variants,
};

const Home: NextPage = () => {
  const router = useRouter();
  const { user } = useUser();

  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const contactRef = useRef(null);

  const x = useMotionValue(200);
  const y = useMotionValue(200);

  const rotateX = useTransform(y, [0, 400], [10, -10]);
  const rotateY = useTransform(x, [0, 400], [-10, 10]);

  function handleMouse(event: any) {
    x.set(event.pageX);
    y.set(event.pageY);
  }

  useEffect(() => {
    if (router.query) {
      const { anchor } = router.query;
      if (anchor === "features") {
        // @ts-ignore
        featuresRef.current.scrollIntoView({ behavior: "smooth" });
      }

      if (anchor === "pricing") {
        // @ts-ignore
        pricingRef.current.scrollIntoView({ behavior: "smooth" });
      }

      if (anchor === "contact") {
        // @ts-ignore
        contactRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [router.query]);

  const handleCTAClick = useCallback(
    () => (user ? router.push("/dashboard") : router.push("/signup")),
    [router, user]
  );

  const handelScrollToFeatures = useCallback(
    () =>
      // @ts-ignore
      featuresRef.current.scrollIntoView({ behavior: "smooth" }),
    []
  );

  const handelScrollToPricing = useCallback(
    () =>
      // @ts-ignore
      pricingRef.current.scrollIntoView({ behavior: "smooth" }),
    []
  );

  const handelScrollToContact = useCallback(
    () =>
      // @ts-ignore
      contactRef.current.scrollIntoView({ behavior: "smooth" }),
    []
  );

  return (
    <>
      <NextSeo
        title="AI Excel Bot | Use AI To Generate Excel Formulas In Seconds"
        description="AI Excel Bot helps you create complex Excel formulas in seconds. Use simple English prompts to generate formulas. AI Excel Bot's formula generator uses state of the art GPT-3 AI model to give you the best results."
        canonical="https://aiexcelbot.com"
        openGraph={{
          url: "https://aiexcelbot.com",
          title: "AI Excel Bot | Use AI To Generate Excel Formulas In Seconds",
          description:
            "AI Excel Bot helps you create complex Excel formulas in seconds. Use simple English prompts to generate formulas. AI Excel Bot's formula generator uses state of the art GPT-3 AI model to give you the best results.",
          images: [{ url: "https://aiexcelbot.com/dashboard-hero.png" }],
          siteName: "AI Excel Bot",
        }}
        twitter={{
          handle: "@0x_gautam",
          cardType: "summary_large_image",
        }}
      />

      <div className="absolute -z-10 top-0 blur-xl h-screen w-full bg-gradient-to-br from-green-100 to-transparent"></div>
      <main
        className={`scrollbar-thin scrollbar-thumb-gray-500 overflow-y-scroll overflow-x-hidden scrollbar-thumb-rounded-full scrollbar-track-rounded-full px-4`}
      >
        <div className="max-w-6xl m-auto">
          <Navbar
            handelScrollToContact={handelScrollToContact}
            handelScrollToFeatures={handelScrollToFeatures}
            handelScrollToPricing={handelScrollToPricing}
          />
        </div>

        {/* HERO */}
        <motion.section
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="min-h-[90vh] max-w-6xl mx-auto md:mt-12 py-6  md:py-12 md:px-0"
          onMouseMove={handleMouse}
        >
          <div className="w-full">
            <div className="mx-auto max-w-3xl text-center">
              <h1 className="text-3xl font-bold md:text-5xl">
                Write Excel and Google Sheets Formulas 10x Faster With AI
              </h1>
              <p className="py-6 text-lg">
                Generate complex formulas using simple text instructions in
                seconds.
              </p>

              <div className="mx-auto w-max">
                <Button onClick={handleCTAClick} variant="Large">
                  Get Started
                </Button>
              </div>
            </div>

            <motion.div
              style={{ rotateX, rotateY }}
              className="mockup-window max-w-3xl mx-auto mt-8 overflow-visible border-2"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.9 }}
              variants={{
                visible: { y: 0, opacity: 1 },
                hidden: { y: 50, opacity: 0 },
              }}
            >
              <div className="px-4 py-8 border-t-2 ">
                <div className="relative sm:-left-8 md:-left-16 mb-4 rounded-xl bg-green-200 sm:w-96 px-4 py-2 ">
                  Sum column A when cell B10 equals the word hello
                </div>
                <div className="relative sm:left-[60%] md:left-[75%] mb-4 rounded-xl bg-green-600 text-white sm:w-60 px-4 py-2 ">
                  =SUMIF(B10,&quot;hello&quot;,A:A)
                </div>
                <div className="relative sm:-left-8 md:-left-16 mb-4 rounded-xl bg-green-200 sm:w-96 px-4 py-2 ">
                  =VLOOKUP(B3,$F$3:$G$8,2,FALSE)
                </div>
                <div className="relative sm:left-[45%] md:left-[65%] rounded-xl bg-green-600 text-white sm:w-96 px-4 py-2 ">
                  <p>1. The formula is a VLOOKUP formula.</p>
                  <p>2. It looks up the value in cell B3.</p>
                  <p>
                    3. It looks for the value in the range of cells from F3 to
                    G8.
                  </p>
                  <p>
                    4. It returns the value from the second column of the range
                    (G3:G8).
                  </p>
                  <p>
                    5. It does not approximate the value if it is not found
                    (FALSE).
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.section>

        {/* FEATURES */}
        <div
          className="max-w-6xl mx-auto py-20 grid grid-cols-1 gap-4 items-stretch"
          ref={featuresRef}
        >
          <motion.section {...sectionVariantProps} className="p-8 rounded-3xl">
            <div className="flex flex-col lg:flex-row-reverse gap-16 items-center">
              <div className="text-center lg:text-left flex-1">
                <h1 className="text-5xl font-bold">Generate Formulas</h1>

                <p className="py-6 text-lg">
                  Easily generate Excel or Google Sheets formulas with simple
                  text prompts.
                </p>
              </div>

              <div className="rounded-xl border border-green-700 overflow-hidden flex-1">
                <img
                  src={"/generate.png"}
                  alt={"Generate Excel And Google Sheets Formulas"}
                  className="w-full"
                />
              </div>
            </div>
          </motion.section>

          <motion.section className="p-8 rounded-3xl" {...sectionVariantProps}>
            <div className="flex flex-col-reverse lg:flex-row-reverse gap-16 items-center">
              <div className="rounded-xl border border-green-700 overflow-hidden flex-1">
                <img
                  src={"/explain.png"}
                  alt={
                    "Let the AI help you understand complex formulas in seconds."
                  }
                  className={"w-full"}
                />
              </div>
              <div className="text-center md:text-left flex-1">
                <h1 className="text-5xl font-bold">
                  Understand Complex Formulas
                </h1>

                <p className="py-6 text-lg">
                  No need to scratch your head to understand complex Excel or
                  Google Sheets formulas. Let the AI help you do that.
                </p>
              </div>
            </div>
          </motion.section>

          <motion.section className="p-8 rounded-3xl" {...sectionVariantProps}>
            <div className="flex flex-col lg:flex-row-reverse gap-16 items-center">
              <div className="text-center md:text-left flex-1">
                <h1 className="text-5xl font-bold">VBA Code Support</h1>

                <p className="py-6 text-lg">
                  No need to waste time writing or understanding VBA. AI Excel
                  Bot can write VBA and explain it to you in seconds.
                </p>
              </div>
              <div className="rounded-xl border border-green-700 overflow-hidden flex-1">
                <img
                  src={"/vba.png"}
                  alt={
                    "Let the AI help you understand complex formulas in seconds."
                  }
                  className={"w-full"}
                />
              </div>
            </div>
          </motion.section>

          <motion.section className="p-8 rounded-3xl" {...sectionVariantProps}>
            <div className="flex flex-col-reverse lg:flex-row-reverse gap-16 items-center">
              <div className="rounded-xl border border-green-700 overflow-hidden flex-1">
                <img
                  src={"/addon.png"}
                  alt={
                    "Let the AI help you understand complex formulas in seconds."
                  }
                  className={"w-full"}
                />
              </div>
              <div className="text-center lg:text-left flex-1">
                <h1 className="text-5xl font-bold">Chrome Addon</h1>

                <p className="py-6 text-lg">
                  Use the power of AI without leaving Google Sheets or Excel.
                </p>

                <a
                  href="https://chrome.google.com/webstore/detail/ai-excel-bot-extension/ehpdalbmeiockgpaikchiciodighaedg"
                  target={"_blank"}
                  rel="noreferrer"
                  className="flex justify-center md:justify-start"
                >
                  <Button variant="Large">Download Extension</Button>
                </a>
              </div>
            </div>
          </motion.section>
        </div>

        {/* PRICING */}
        <motion.section
          className="py-20 px-10 max-w-6xl mx-auto"
          ref={pricingRef}
          {...pricingSectionProps}
        >
          <div className="flex flex-col-reverse lg:flex-row gap-16">
            <div>
              <h2 className="text-5xl font-bold">Simple Pricing</h2>
              <p className="py-6 text-lg">For all kind of users.</p>

              <PricingTable showFree={true} />
            </div>
          </div>
        </motion.section>

        <div className="max-w-6xl mx-auto my-10 bg-gradient-to-r from-green-200 to-green-50 p-8 rounded-3xl flex flex-col md:flex-row gap-4 md:justify-around items-center">
          <h3 className="text-3xl text-center sm:text-left font-bold">
            Access the power of AI
          </h3>
          <motion.button
            className={`
          text-lg font-bold px-8 p-4 bg-green-600 text-white text-center rounded-xl duration-300 flex items-center`}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleCTAClick}
          >
            Get Started
          </motion.button>
        </div>

        {/* CONTACT */}
        <motion.section
          {...pricingSectionProps}
          className="max-w-6xl mx-auto"
          ref={contactRef}
        >
          <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
            <ContactForm
              title="Contact Us"
              subtitle="Got a technical issue? Want to send feedback about a feature? Get in
        touch with the founder."
            />
          </div>
        </motion.section>
      </main>
      {/* FOOTER */}
      <Footer
        handelScrollToContact={handelScrollToContact}
        handelScrollToFeatures={handelScrollToFeatures}
        handelScrollToPricing={handelScrollToPricing}
      />
    </>
  );
};

export default Home;
