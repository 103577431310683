import Button from "@components/Utils/Button";
import Link from "next/link";
import { useMemo } from "react";
import { pricing } from "../../stripe/products";
import { useUser } from "../../utils/AuthContext";

type Props = {
  showFree: boolean;
};

const PricingTable = ({ showFree }: Props) => {
  const { user } = useUser();

  const _pricing = useMemo(() => {
    let tempPricing = pricing;

    // remove free items
    if (!showFree) {
      tempPricing = pricing.filter((p) => p.title !== "Free");
    }

    // remove items based on logged in user
    if (user) {
      tempPricing = tempPricing.filter((p) => p.displayLoggedIn);
    } else {
      tempPricing = tempPricing.filter((p) => p.displayLoggedOut);
    }

    return tempPricing;
  }, [showFree, user]);

  return (
    <section>
      <div className="mobile-padding-disable p-6 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div style={{
          gridTemplateColumns: `${showFree ? 'repeat(3, minmax(0, 400px))' : 'repeat(2, minmax(0, 400px))'}`
        }} className="space-y-8 flex flex-wrap lg:grid sm:gap-6 xl:gap-6 lg:space-y-0 gap-6">
          {
            _pricing.map((p, i) => (
              <div
                className={`relative flex flex-col justify-between p-6 mx-auto max-w-lg text-center rounded-lg xl:p-8 ${p.isAnnual
                  ? "bg-gradient-to-b from-[#C0F4CC] to-[#F0FCF2] border border-[#419547] rounded-none rounded-b-lg"
                  : "bg-white border-2"
                  }`}
                key={i}
              >
                {p.isAnnual && (
                  <span
                    style={{ width: 'calc(100% + 2px)' }}
                    className="bg-[#419547] absolute -top-[36px] -left-[1px] h-[36px] flex items-center justify-center text-[12px] font-semibold text-[#FFFFFF] rounded-t-2xl"
                  >
                    Best Value
                  </span>
                )}
                <h3 className={`text-[23px] font-bold flex items-center justify-between text-left gap-2`}>
                  {p.title}
                  {p.isAnnual && (
                    <div className="h-[24px] text-[12px] px-2 bg-[#419547] text-[#FFFFFF] flex items-center justify-center rounded-full">
                      Save 30%
                    </div>
                  )}
                </h3>
                <div className="text-[33px] font-extrabold leading-[40px] flex items-baseline">
                  {p.price}
                  {p.isAnnual ? "/year" : "/month"}
                </div>
                <div className={`h-[1px] w-full ${p.isAnnual ? 'bg-[#419547]' : 'bg-[#BFC2CA]'} my-4`}></div>
                <ul role="list" className="mb-8 space-y-4 text-left">
                  {p.benefits.map((b, j) => (
                    <li className="flex items-center justify-between space-x-3" key={j}>
                      <div className="flex items-center gap-2">
                        {p.isAnnual ? (
                          <img src={'/check-annual.svg'} alt="" />
                        ) : (
                          <img src={'/check-default.svg'} alt="" />
                        )}
                        <span className="text-[14px] text-[#2E3443]">{b}</span>
                      </div>
                      {b === 'Access to our Chrome extension' && (
                        <img width={24} src={'/sheets-logo.svg'} alt="" />
                      )}
                      {b === 'Access to our Excel add-in' && (
                        <img width={32} src={'/excel-logo.svg'} alt="" />
                      )}
                    </li>
                  ))}
                </ul>

                <div className="w-full">
                  {user ? (
                    <Link href={`/checkout/${p.priceId}/${p.title}`}>
                      {p.isAnnual ? (
                        <button className="bg-[#6BD47B] w-full text-[#2E3443] font-bold h-[60px] rounded-lg border border-[#6BD47B]">
                          Upgrade to Pro
                        </button>
                      ) : (
                        <button className="bg-[#FFFFFF] w-full text-[#2E3443] font-bold h-[60px] rounded-lg border border-[#2E3443]">
                          Upgrade to Pro
                        </button>
                      )}
                    </Link>
                  ) : (
                    <Link href={user ? "/dashboard" : "/signup"}>
                      <a>
                        {p.isAnnual ? (
                          <button className="bg-[#6BD47B] w-full text-[#2E3443] font-bold h-[60px] rounded-lg border border-[#6BD47B]">Get started</button>
                        ) : (
                          <button className="bg-[#FFFFFF] w-full text-[#2E3443] font-bold h-[60px] rounded-lg border border-[#2E3443]">Get started</button>
                        )}
                      </a>
                    </Link>
                  )}
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </section >
  );
};

export default PricingTable;
