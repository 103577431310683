export type ProductPricing = {
  title: string,
  price: string,
  priceId: string,
  benefits: string[],
  isLocal: boolean,
  isAnnual?: boolean,
  displayLoggedIn: boolean,
  displayLoggedOut: boolean,
  paypal_plan_id: string,
}

export const pricing: ProductPricing[] = [
  {
    title: "Free",
    price: "$0",
    priceId: "",
    benefits: [
      "Limited access to AI Excel Bot",
      "5 Formula, Code or Task generation and explaination per month",
      "Access to our Chrome extension",
      "email support",
    ],
    isLocal: true,
    displayLoggedIn: false,
    displayLoggedOut: true,
    paypal_plan_id: '',
  },
  {
    title: "Pro",
    price: "$5.99",
    priceId: "price_1OqWpWGUC8HbciH8h68KMVAH",
    benefits: [
      "Full access to AI Excel Bot",
      "Access to our Chrome extension",
      "Access to our Excel add-in",
      "Unlimited Formula generation per month",
      "Unlimited Formula explanations per month",
      "Unlimited VBA code generations per month",
      "Unlimited VBA code explanations per month",
      "Unlimited Task explanations per month",
      "Priority support",
    ],
    isLocal: false,
    isAnnual: false,
    displayLoggedIn: true,
    displayLoggedOut: true,
    paypal_plan_id: 'P-9AG89956MN843883MMXSYXII',
  },
  {
    title: "Pro Annual",
    price: "$49.99",
    priceId: "price_1OqWuwGUC8HbciH8pjYB8MQh",
    benefits: [
      "Full access to AI Excel Bot",
      "Access to our Chrome extension",
      "Access to our Excel add-in",
      "Unlimited Formula generation per month",
      "Unlimited Formula explanations per month",
      "Unlimited VBA code generations per month",
      "Unlimited VBA code explanations per month",
      "Unlimited Task explanations per month",
      "Priority support",
    ],
    isLocal: false,
    isAnnual: true,
    displayLoggedIn: true,
    displayLoggedOut: true,
    paypal_plan_id: 'P-7AM83542T77522743MXSYYII',
  },
];

