import { useRouter } from "next/router";
import Link from "next/link";

import { motion } from "framer-motion";

import { useUser } from "../../utils/AuthContext";
import Button from "@components/Utils/Button";

type Props = {
  handelScrollToContact?: () => void;
  handelScrollToFeatures?: () => void;
  handelScrollToPricing?: () => void;
};

const Navbar = ({
  handelScrollToContact = () => { },
  handelScrollToFeatures = () => { },
  handelScrollToPricing = () => { },
}: Props) => {
  const router = useRouter();
  const { user, logOut } = useUser();

  const handleRouteToHome = (anchor: string) => {
    router.push(`/?anchor=${anchor}`);
  };

  return (
    <div className="transition-all ease-in flex justify-between items-center py-4 px-8 max-w-6xl m-auto md:px-16 ">
      <div className="flex items-center gap-6 md:gap-12">
        <div className="flex items-center gap-2 md:gap-4">
          <div className="dropdown">
            <label tabIndex={0} className="md:hidden">
              <span className="material-icons">menu</span>
            </label>
            <ul
              tabIndex={0}
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li
                onClick={() =>
                  router.pathname === ""
                    ? handelScrollToFeatures()
                    : handleRouteToHome("features")
                }
              >
                <a>Features</a>
              </li>
              <li>
                <Link href="/formulas">
                  Formulas
                </Link>
              </li>
              <li
                tabIndex={0}
                onClick={() =>
                  router.pathname === ""
                    ? handelScrollToPricing()
                    : handleRouteToHome("pricing")
                }
              >
                <a>Pricing</a>
              </li>
              <li
                onClick={() =>
                  router.pathname === ""
                    ? handelScrollToContact()
                    : handleRouteToHome("contact")
                }
              >
                <a>Contact</a>
              </li>

              {user && (
                <li>
                  <Link href="/dashboard">
                    <a
                      className={
                        router.pathname === "/dashboard" ? "text-primary" : ""
                      }
                    >
                      Dashboard
                    </a>
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <Link href={"/"}>
            <a className="flex items-center gap-4 text-lg md:text-xl md:font-bold ml-4 md:ml-0">
              <img
                src={"/graph.svg"}
                alt={"logo"}
                className="h-8 w-8 md:h-12 md:w-12 "
              />
              <span className="hidden md:block">AI Excel Bot</span>
            </a>
          </Link>
        </div>
        <div className="hidden md:flex font-semibold">
          <ul className="flex gap-8">
            <li
              onClick={() =>
                router.pathname === ""
                  ? handelScrollToFeatures()
                  : handleRouteToHome("features")
              }
            >
              <button className="p-2 rounded-2xl duration-300 text-gray-500 hover:text-green-900 hover:bg-green-200">
                Features
              </button>
            </li>
            <li>
              <Link href="/formulas">
                <button className="p-2 rounded-2xl duration-300 text-gray-500 hover:text-green-900 hover:bg-green-200">
                  Formulas
                </button>
              </Link>
            </li>
            <li
              onClick={() =>
                router.pathname === ""
                  ? handelScrollToPricing()
                  : handleRouteToHome("pricing")
              }
            >
              <button className="p-2 rounded-2xl text-gray-500 duration-300 hover:text-green-900 hover:bg-green-200">
                Pricing
              </button>
            </li>
            <li
              onClick={() =>
                router.pathname === ""
                  ? handelScrollToContact()
                  : handleRouteToHome("contact")
              }
            >
              <button className="p-2 rounded-2xl text-gray-500 duration-300 hover:text-green-900 hover:bg-green-200">
                Contact
              </button>
            </li>

            {user && (
              <li>
                <Link href="/dashboard">
                  <a>
                    <button className="p-2 rounded-2xl text-gray-500 duration-300 hover:text-green-900 hover:bg-green-200">
                      Dashboard
                    </button>
                  </a>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="flex items-center gap-2 md:gap-4">
        {user ? (
          <Button variant="Large" onClick={() => logOut()}>
            Logout
          </Button>
        ) : (
          <>
            <Link href={"/signup"}>
              <a>
                <Button variant="Large">Signup</Button>
              </a>
            </Link>
            <Link href={"/login"}>
              <motion.a
                className="cursor-pointer px-3 py-1 md:px-6 md:py-3 font-semibold rounded-xl hover:bg-gray-200 duration-300 hover:text-black"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                Login
              </motion.a>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;
