import Link from "next/link";
import { useRouter } from "next/router";

type Props = {
  handelScrollToContact?: () => void;
  handelScrollToFeatures?: () => void;
  handelScrollToPricing?: () => void;
};

const Footer = ({
  handelScrollToContact = () => {},
  handelScrollToFeatures = () => {},
  handelScrollToPricing = () => {},
}: Props) => {
  const router = useRouter();

  const handleRouteToHome = (anchor: string) => {
    router.push(`/?anchor=${anchor}`);
  };

  return (
    <footer className="footer p-10 max-w-6xl mx-auto">
      <div>
        <span className="text-2xl font-bold">AI Excel Bot</span>
        <p>A product by Query Labs.</p>
        <p>Copyright © {new Date().getFullYear()} - All right reserved</p>
      </div>
      <div>
        <a
          className="link link-hover"
          onClick={() =>
            router.pathname === ""
              ? handelScrollToFeatures()
              : handleRouteToHome("features")
          }
        >
          Features
        </a>
        <a
          className="link link-hover"
          onClick={() =>
            router.pathname === ""
              ? handelScrollToPricing()
              : handleRouteToHome("pricing")
          }
        >
          Pricing
        </a>
        <a
          className="link link-hover"
          onClick={() =>
            router.pathname === ""
              ? handelScrollToContact()
              : handleRouteToHome("contact")
          }
        >
          Contact
        </a>
      </div>
      <div>
        <Link href={"/terms-of-use"}>
          <a className="link link-hover">Terms of use</a>
        </Link>
        <Link href={"/privacy-policy"}>
          <a className="link link-hover">Privacy policy</a>
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
