import Button from "@components/Utils/Button";
import { useState } from "react";

type Props = {
  title: string;
  subtitle: string;
};

const ContactForm = ({ title, subtitle }: Props) => {
  const [result, setResult] = useState("");

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setResult("Sending");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        access_key: process.env.NEXT_PUBLIC_WEB3FORMS_API_KEY,
        subject: e.target.subject.value,
        email: e.target.email.value,
        message: e.target.message.value,
      }),
    });
    const result = await response.json();

    if (result.success) {
      console.log("Success");
      setResult("Success");
    } else {
      setResult("Error");
    }
  };

  return (
    <>
      {title && (
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center">
          {title}
        </h2>
      )}
      {subtitle && (
        <p className="mb-8 lg:mb-16 font-light text-center sm:text-xl">
          {subtitle}
        </p>
      )}
      <form onSubmit={onSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2 text-sm font-medium">
            Your email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="shadow-sm input input-bordered w-full"
            placeholder="name@aiexcelbot.com"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="subject" className="block mb-2 text-sm font-medium">
            Subject
          </label>
          <input
            type="text"
            id="subject"
            name="subject"
            className="input input-bordered w-full"
            placeholder="Let us know how we can help you"
            required
          />
        </div>
        <div className="sm:col-span-2 mb-4">
          <label htmlFor="message" className="block mb-2 text-sm font-medium">
            Your message
          </label>
          <textarea
            id="message"
            name="message"
            rows={6}
            className="block p-2.5 w-full textarea textarea-bordered"
            placeholder="Leave a comment..."
          ></textarea>
        </div>

        <div className="mb-4">
          {result === "Success" && (
            <div className="border border-success p-4 rounded-lg">
              <p>Email sent successfully. We will write back to you soon.</p>
            </div>
          )}
          {result === "Error" && (
            <div className="border border-error p-4 rounded-lg">
              <p>Error sending email. Please try again</p>
            </div>
          )}
        </div>

        <Button type="submit" loading={result === "Sending"}>
          Send Message
        </Button>
      </form>
    </>
  );
};

export default ContactForm;
